@tailwind base;
@tailwind components;
@tailwind utilities;



/*--------------------------------------------------------------
# Filter Start
--------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,500;0,600;0,700;0,800;1,300&display=swap');


@media only screen and (min-width: 1100px) and (max-width: 1920px) {
    .cntrl-width {
        width: 20%;
    }
}

@media only screen and (min-width: 1100px) and (max-width: 1920px) {
    .cntrl-width1 {
        width: 60%;
    }
}

.modal-backdrop {
    --bs-backdrop-zindex: 0;
}

.sidebar-submenu {
    background-color: rgba(255,255,255,.05);
    border-radius: 5px;
    padding-bottom: 5px !important;
    box-shadow: 0 0 10px 7px rgb(0 0 0 / 3%);
}
.MuiTabs-flexContainer{
    display:flex;
    justify-content:center;
    height:50px !important;
}

#dataTable tr:hover {
    background-color: #ddd;
}
.notefont{
    font-size:11px;
    padding-bottom:2px;
}
.MuiTabs-flexContainer {
    align-items: center !important;
    background: #f3f4f4;

}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    opacity: 1;
    -webkit-text-fill-color: #000 !important;
    background-color: #e9ecef !important;
}

.highlighted {
    background-color: #FFF5CC;
}

.table-responsive::-webkit-scrollbar {
    width: 5px;
    height: 13px;
}

.table-responsive::-webkit-scrollbar-track {
    background: #c7c7c7;
    border-top: 5px solid white;
    border-bottom: 5px solid white;
}

.table-responsive::-webkit-scrollbar-thumb {
    background: #daac6d;
    border-radius: 5px;
    border-top: 3px solid white;
    border-bottom: 3px solid white;
}

.css-1q60rmi-MuiAutocomplete-endAdornment {
    position: absolute !important;
    right: 0px !important;
    top: calc(50% - 18px) !important;
    background: white !important;
}

.MuiInputBase-input::placeholder {
    color: #000 !important;
}

.MuiAutocomplete-endAdornment {
    margin-right: 5px !important;
}

.MuiInputBase-input {
    height: 20px !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #fdac41 !important;
    background-color: #fdac414a;
    box-shadow: 0 0.125rem 0.25rem rgba(147,158,170,.4);
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 7px 5px 7px 7px !important;
    font-size: 13px !important;
}

.css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 7px 6px 7px 6px !important;
    font-size: 14px !important;
}

.MuiAutocomplete-listbox {
    font-size: 14px !important;
}

div.MuiAutocomplete-root {
    margin-bottom: 0 !important;
}

.MuiAutocomplete-popper {
    z-index: 10 !important;
}

.MuiOutlinedInput-root {
    padding: 0px !important;
}

.highlight {
    background-color: yellow !important;
}

.sidebar-submenu li:hover {
    background-color: #1d262d;
    border-radius: 5px;
}

.sidebar-submenu a {
    color: #222d32 !important;
}

    .sidebar-submenu a:hover {
        color: #fff !important;
    }

a.active {
    background: #a98451;
    color:white;
    padding:4px
}

.content-left-align {
    text-align: left !important;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #D9AE6D !important;
}
.Exp-btn {
    background-color: #D9AE6D
}
.add-btn {
    background-color: rgb(104, 157, 21)
}
    .add-btn:hover {
        background-color: #477212;
        border-color: var(--bs-btn-hover-border-color)
    }
.apply-btn:hover {
    background-color: #477212!important;
    border-color: var(--bs-btn-hover-border-color)!important;
}
.reject-btn:hover {
    background-color: #c5242e !important;
    border-color: var(--bs-btn-hover-border-color) !important;
}

.clear-btn:hover {
    background-color: rgb(203 81 88) !important;
    border-color: var(--bs-btn-hover-border-color) !important;
}
.Export-btn {
    background-color: #1bbec7
}
    .Export-btn:hover {
        background-color: #20989f;
        border-color: var(--bs-btn-hover-border-color)
    }

.Exp-btn:hover {
    background-color: #A88551;
    border-color: var(--bs-btn-hover-border-color)
}
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root:hover {
    background-color: #D9AE6D !important;
}

.HistoryColor {
    background: #F2DDC1 !important;
}

.react-confirm-alert-body {
    font-family: 'Karla', sans-serif !important;
    padding: 15px !important;
    text-align: left;
    width: 300px !important;
    background: #000013 !important;
    border-radius: 10px !important;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #ffe0e0 !important;
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #ccc;
    z-index: 1000;
}


.react-confirm-alert-overlay {
    background: none !important;
    z-index: 999 !important;
    align-items: flex-start !important;
}



.dis-btn button {
    border-radius: 5px !important;
    width: fit-content !important;
    padding: 5px 10px;
}
/*
::placeholder {
    color: #878686 !important;
}*/
.swal2-popup {
    display: grid;
    /* height: 298px;*/
    font-size: small;
}


.Dashboard {
    background: url('../public/assets/img/Dashboard.jpg');
    background-size: cover;
    background-position: center;
    height: 88vh;
}

@media (min-width : 1100px) {
    .DrsStyle {
        width: 20% !important;
    }
}


.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 5px 5px 7px 8px !important;
}

.css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 5px 4px 5px 5px !important;
}


.Required {
    color: red;
}

.css-2gftvx.Mui-selected {
    background-color: rgb(34 45 50) !important;
    COLOR: #FFF;
}

@media (min-width: 1200px) {
    .modal-xlg {
        width: 90% !important;
    }
}

@media (min-width: 1200px) {
    .modal-xlg {
        width: 90%;
    }
}

.img {
    height: 50px !important;
    width: 100% !important;
    margin-left: 15px;
}

.password-policy {
    background: #f0f1f4;
}

.password-policy-border {
    border-top: 2px solid #1BB3E6 !important;
    border-right: 1px #ababab solid !important;
    border-left: 1px #ababab solid !important;
    border-bottom: 1px #ababab solid !important;
    overflow:hidden;
    border-radius:10px;
}

.MuiOutlinedInput-root {
    padding: 0px !important;
}

.table {
    text-transform: none !important;
}

div.MuiAutocomplete-root {
    margin-bottom: 0 !important;
    background: white !important;
}

.MuiAutocomplete-popper {
    z-index: 10 !important;
}

.css-1s5lphu-MuiPopper-root-MuiAutocomplete-popper {
    z-index: 10 !important
}

.css-18nc3u2 .MuiOutlinedInput-root {
    padding: 0 !important;
}

.MuiInputBase-root fieldset {
    display: none !important;
}

.react-datepicker-wrapper {
    display: block !important
}

.react-datepicker-popper {
    z-index: 1090 !important
}

.css-1q60rmi-MuiAutocomplete-endAdornment {
    top: calc(50% - 14px) !important;
    background: white !important;
}

.css-2iz2x6 {
    top: calc(50% - 14px) !important;
}




.error-check:focus {
    border: 1px solid red !important;
}

.filter-section select {
    margin: 0;
}

.filter-btn {
    width: 6rem;
    height: 28px;
    background-color: var(--mycolorFilterCheckText);
    color: white;
    border-radius: 5px;
}

.filter-list {
    margin: 5px 0px;
}

.filter-select {
    width: 100%;
    margin: 0 !important;
}

.dropdown-filter {
    width: 5rem;
    padding: 10px !important;
    z-index: 9999;
}

.filter-search-bar {
    height: 30px;
    justify-content: space-between;
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 0px 5px;
    position: relative;
    margin: 0;
}

    .filter-search-bar input {
        width: 6rem;
        border: none;
    }

        .filter-search-bar input:focus {
            outline: none;
            border: none;
        }

/*--------------------------------------------------------------
# Filter end
--------------------------------------------------------------*/

.btn-disable:disabled {
    cursor: wait
}

.sticky-top {
    z-index: 0 !important
}

:root {
    scroll-behavior: smooth;
}

body {
    font-family: 'Karla', sans-serif !important;
    /*background: #f6f9ff;*/
    color: #444444;
}

a {
    color: #a5804d;
    text-decoration: none;
}

    a:hover {
        color: #717ff5;
        text-decoration: none;
    }

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Karla', sans-serif;
}

/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/
#main {
    margin-top: 60px;
    padding: 15px 12px;
    transition: all 0.3s;
}

@media (max-width: 1199px) {
    #main {
        padding: 20px;
    }
}

/*--------------------------------------------------------------
# Page Title
--------------------------------------------------------------*/
.pagetitle {
    margin-bottom: 10px;
}

    .pagetitle h1 {
        font-size: 24px;
        margin-bottom: 0;
        font-weight: 600;
        color: #012970;
    }

.collapse {
    visibility: inherit !important;
}

.content-box {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, .15);
    border-radius: 10px;
}

.heading-box, .heading-box-isUpdate {
    background-color: white;
    border-radius: 4px 4px 0 0;
    padding: 19px 1.25rem;
}
.heading-boxModal, .heading-boxModal-isUpdate {
    background-color: white;
    border-radius: 4px 4px 0 0;
    padding: 10px 1.25rem;
}
.heading-box1, .heading-box1-isUpdate {
    display: "flex";
    background-color: white;
    border-radius: 4px 4px 0 0;
    padding: 12px 0.49rem;
    margin-top: 13px;
}

.heading-box .dropstart button {
    border: 0px;
}

.recoard-bata table {
    white-space: nowrap;
   
}

.recoard-bata table tbody tr td button {
    height: 25px;
    width: 25px;
    border-radius: 5%;
    color: #fff !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  
}

        .recoard-bata table tbody tr td button i {
            font-size: 12px;
        }

    .recoard-bata table tbody tr td .text-success1 {
        background-color: #198754;
        margin-right: 8px;
    }

    .recoard-bata table tbody tr td .text-danger {
        background-color: #dc3545;
    }

    .recoard-bata table tbody tr td .text-success:hover {
        background-color: #198754;
    }

.MuiAutocomplete-hasClearIcon {
    margin-right: 4px;
}

.text-primary {
    color: black !important;
}

.recoard-bata table tr td {
    font-size: 14px;
    padding: 4px 18px;
    color: #000;
    font-weight: 500;
}

.recoard-bata table tr th {
    background-color: #e3e3e3;
    color: #222;
    font-size: 14px;
    padding: 6px 8px;
}

.recoard-bata table tbody tr td .text-danger:hover {
    background-color: #dc3545;
}

.heading h6 {
    font-size: 16px;
    font-weight: 700;
}



.service-content-area {
    border-radius: 0 0 3px 3px;
    padding: 1.25rem;
    margin-top:0px;
}

.service-content-area1 {
    border-radius: 0 0 3px 3px;
    padding: 1.25rem;
}

.service-content-area-dashboard {
    border: 1px solid #52545c;
    border-radius: 0 0 3px 3px;
    padding: 1.25rem;
}

.recoard-bata {
    padding: 2px !important;
    
}

@media (min-width: 270px) {
    .autocomplete-box {
        width: 196px;
    }
}
.css-1c32n2y-MuiBadge-root {
    margin-top: 15px !important;
    margin-right:10px !important;
}
.css-1rzb3uu-MuiBadge-root {
    margin-top: 15px !important;
    margin-right: 10px !important;
}

.fields input, textarea, select {
    border: 1px solid #ddd !important;
    font-size: 14px;
    font-weight: 400;
    padding: 5px;
    text-decoration: none;
    outline: none;
    outline-width: 0;
    border-radius: 5px;
    transition: 0.3s;
    color: #6e707e !important;
}

.fields {
    margin-bottom: 10px;
}
   /* .fields span {
        text-decoration: none;
        font-size: 12px !important;
        font-weight: 300 !important;
    }*/
.Filter-Search-Button-main {
    background: #222d32;
    border-radius: 5px;
    color: #fff;
    margin: 3px;
    padding: 0 5px;
}

.lms-new-button .search-btn {
    border: 1px solid var(--mycolorFilterCheckText);
    color: royalblue;
}

    .lms-new-button .search-btn:before {
        background-color: var(--mycolorFilterCheckText);
    }

.dropdown-fillter {
    padding: 10px !important;
    z-index: 9999;
}

    .dropdown-fillter p {
        margin-bottom: 8px;
    }

        .dropdown-fillter p:last-child {
            margin-bottom: 0px;
        }

.fields select {
    padding: 0px 10px;
    height: 33px;
}

.file-se input {
    padding: 5px 10px;
    width: 100%;
}

.fields label {
    margin-bottom: 0;
    color: #000;
    font-weight: 500;
}

.fields .form-control {
    color: #6e707e;
}

.chbx {
    padding-bottom: 1rem;
}

.form-switch {
    padding-bottom: 1rem;
    justify-content: flex-start !important;
}
.form-switch1 {
    justify-content: flex-start !important;
}

.chbx input {
    border-radius: 50% !important;
}

.fields input:focus, textarea:focus, select:focus {
    outline: 0 !important;
    box-shadow: none !important;
    border: 1px solid #A78350 !important;
}

.lms-new-button button {
    padding: 6.5px 12px;
    border-radius: 5px;
    box-shadow: 0 0 10px 7px rgb(0 0 0 / 3%);
    background-color: #fff;
    border: 0px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-right: 10px;
}

    .lms-new-button button:last-child {
        margin-right: 0px;
    }

.lms-new-button .save-btn {
    border: 1px solid #0f9d58;
    color: #0f9d58;
}
.lms-new-button .Prev-btn {
    border: 1px solid #4285F4;
    color: #4285F4;
}
.lms-new-button .export-btn {
    border: 1px solid #0f60b6;
    color: #0f60b6;
}

.lms-new-button .reset-btn {
    border: 1px solid #dd3c30;
    color: #dd3c30;
}
.lms-new-button .Process-btn {
    border: 1px solid #497405;
    color: #497405;
}

.lms-new-button .print-btn {
    border: 1px solid #1BB3E6;
    color: #1BB3E6;
}

.lms-new-button button:before {
    border-radius: 20px;
    content: '';
    position: absolute;
    right: -10px;
    top: -11px;
    transition: all .3s;
    width: 25px;
    height: 25px;
    z-index: -1;
}

.lms-new-button button:hover:before {
    border-radius: 0;
    height: 100%;
    right: 0;
    top: 0;
    width: 100%;
    color: #fff;
}

.lms-new-button button:hover {
    color: #fff;
}

.lms-new-button .save-btn:before {
    background-color: #0f9d58;
}
.lms-new-button .Prev-btn:before {
    background-color: #4285F4;
}
.lms-new-button .export-btn:before {
    background-color: #0f60b6;
}

.lms-new-button .reset-btn:before {
    background-color: #dd3c30;
}
.lms-new-button .Process-btn:before {
    background-color: #497405;
}
.lms-new-button .print-btn:before {
    background-color: #1BB3E6;
}

.heading-box {
    gap: 14px !important;
    align-items: center;
}
.heading-box2 {
    gap: 2px !important;
    padding-top: 8px;
    padding-left: 20px;
}
.modal {
    --bs-modal-width: 600px !important;
}


.dropstart button {
    padding: 0px;
}

    .dropstart button:focus {
        border: 0px !important;
    }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.spinner {
    display: flex;
    justify-content: center;
    align-content: center;
}
/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background: #4154f1;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}

    .back-to-top i {
        font-size: 24px;
        color: #fff;
        line-height: 0;
    }

    .back-to-top:hover {
        background: #6776f4;
        color: #fff;
    }

    .back-to-top.active {
        visibility: visible;
        opacity: 1;
    }

/*--------------------------------------------------------------
# Override some default Bootstrap stylings
--------------------------------------------------------------*/
/* Dropdown menus */
.dropdown-menu {
    border-radius: 4px;
    padding: 10px 0;
    animation-name: dropdown-animate;
    animation-duration: 0.2s;
    animation-fill-mode: both;
    border: 0;
    box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}

    .dropdown-menu .dropdown-header,
    .dropdown-menu .dropdown-footer {
        text-align: center;
        font-size: 15px;
        padding: 10px 25px;
    }

        .dropdown-menu .dropdown-footer a {
            color: #444444;
            text-decoration: underline;
        }

            .dropdown-menu .dropdown-footer a:hover {
                text-decoration: none;
            }

    .dropdown-menu .dropdown-divider {
        color: #a5c5fe;
        margin: 0;
    }

    .dropdown-menu .dropdown-item {
        font-size: 14px;
        padding: 10px 15px;
        transition: 0.3s;
    }

        .dropdown-menu .dropdown-item i {
            margin-right: 10px;
            font-size: 18px;
            line-height: 0;
        }

        .dropdown-menu .dropdown-item:hover {
            background-color: #f6f9ff;
        }

@media (min-width: 768px) {
    .dropdown-menu-arrow::before {
        content: "";
        width: 13px;
        height: 13px;
        background: #fff;
        position: absolute;
        top: -7px;
        right: 20px;
        transform: rotate(45deg);
        border-top: 1px solid #eaedf1;
        border-left: 1px solid #eaedf1;
    }
}

@keyframes dropdown-animate {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

    0% {
        opacity: 0;
    }
}

/* Light Backgrounds */
.bg-primary-light {
    background-color: #cfe2ff;
    border-color: #cfe2ff;
}

.bg-secondary-light {
    background-color: #e2e3e5;
    border-color: #e2e3e5;
}

.bg-success-light {
    background-color: #d1e7dd;
    border-color: #d1e7dd;
}

.bg-danger-light {
    background-color: #f8d7da;
    border-color: #f8d7da;
}

.bg-warning-light {
    background-color: #fff3cd;
    border-color: #fff3cd;
}

.bg-info-light {
    background-color: #cff4fc;
    border-color: #cff4fc;
}

.bg-dark-light {
    background-color: #d3d3d4;
    border-color: #d3d3d4;
}

/* Card */
.card {
    margin-bottom: 30px;
    border: #947345 2px solid;
    display: flex;
    border-radius: 8px;
    min-height: 600px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.card-header,
.card-footer {
    border-color: #ebeef4;
    background-color: #fff;
    color: #e07b00;
    padding: 15px;
    font-weight: bold;
    white-space: nowrap;
}

.card-title {
    padding: 20px 0 15px 0;
    font-size: 18px;
    font-weight: 500;
    color: #012970;
    font-family: "Poppins", sans-serif;
}

    .card-title span {
        color: #899bbd;
        font-size: 14px;
        font-weight: 400;
    }

.card-body {
    padding: 0 20px 20px 20px;
}

.card-img-overlay {
    background-color: rgba(255, 255, 255, 0.6);
}

/* Alerts */
.alert-heading {
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
}

/* Close Button */
.btn-close {
    background-size: 25%;
}

    .btn-close:focus {
        outline: 0;
        box-shadow: none;
    }

/* Accordion */
.accordion-item {
    border: 1px solid #ebeef4;
}

.accordion-button:focus {
    outline: 0;
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: #012970;
    background-color: #f6f9ff;
}

.accordion-flush .accordion-button {
    padding: 15px 0;
    background: none;
    border: 0;
}

    .accordion-flush .accordion-button:not(.collapsed) {
        box-shadow: none;
        color: #4154f1;
    }

.accordion-flush .accordion-body {
    padding: 0 0 15px 0;
    color: #3e4f6f;
    font-size: 15px;
}

/* Breadcrumbs */
.breadcrumb {
    font-size: 14px;
    font-family: "Nunito", sans-serif;
    color: #899bbd;
    font-weight: 600;
}

    .breadcrumb a {
        color: #899bbd;
        transition: 0.3s;
    }

        .breadcrumb a:hover {
            color: #51678f;
        }

    .breadcrumb .breadcrumb-item::before {
        color: #899bbd;
    }

    .breadcrumb .active {
        color: #51678f;
        font-weight: 600;
    }

/* Bordered Tabs */
.nav-tabs-bordered {
    border-bottom: 2px solid #ebeef4;
}

    .nav-tabs-bordered .nav-link {
        margin-bottom: -2px;
        border: none;
        color: #2c384e;
    }

        .nav-tabs-bordered .nav-link:hover,
        .nav-tabs-bordered .nav-link:focus {
            color: #4154f1;
        }

        .nav-tabs-bordered .nav-link.active {
            background-color: #fff;
            color: #4154f1;
            border-bottom: 2px solid #4154f1;
        }

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.logo {
    line-height: 1;
}


    .logo span {
        font-size: 26px;
        font-weight: 700;
        color: #012970;
        font-family: "Nunito", sans-serif;
    }

.header {
    transition: all 0.5s;
    z-index: 997;
    height: 80px;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
}

    .header .toggle-sidebar-btn {
        color: #1d262d;
        cursor: pointer;
        font-size: 32px;
        padding-left: 10px;
    }

    .header .search-bar {
        min-width: 360px;
        padding: 0 20px;
    }

@media (max-width: 1199px) {
    .header .search-bar {
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
        background: white;
        z-index: 9999;
        transition: 0.3s;
        visibility: hidden;
        opacity: 0;
    }

    .header .search-bar-show {
        top: 60px;
        visibility: visible;
        opacity: 1;
    }
}

.header .search-form {
    width: 100%;
}

    .header .search-form input {
        border: 0;
        font-size: 14px;
        color: #012970;
        border: 1px solid rgba(1, 41, 112, 0.2);
        padding: 7px 38px 7px 8px;
        border-radius: 3px;
        transition: 0.3s;
        width: 100%;
    }

        .header .search-form input:focus,
        .header .search-form input:hover {
            outline: none;
            box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
            border: 1px solid rgba(1, 41, 112, 0.3);
        }

    .header .search-form button {
        border: 0;
        padding: 0;
        margin-left: -30px;
        background: none;
    }

        .header .search-form button i {
            color: #012970;
        }

/*--------------------------------------------------------------
# Header Nav
--------------------------------------------------------------*/
.header-nav ul {
    list-style: none;
}

.header-nav > ul {
    margin: 0;
    padding: 0;
}

.header-nav .nav-icon {
    font-size: 22px;
    color: #012970;
    margin-right: 25px;
    position: relative;
}

.header-nav .nav-profile {
    color: #1d262d;
}

    .header-nav .nav-profile img {
        max-height: 36px;
    }

    .header-nav .nav-profile span {
        font-size: 14px;
        font-weight: 600;
    }

.header-nav .badge-number {
    position: absolute;
    inset: -2px -5px auto auto;
    font-weight: normal;
    font-size: 12px;
    padding: 3px 6px;
}

.header-nav .notifications {
    inset: 8px -15px auto auto !important;
}

    .header-nav .notifications .notification-item {
        display: flex;
        align-items: center;
        padding: 15px 10px;
        transition: 0.3s;
    }

        .header-nav .notifications .notification-item i {
            margin: 0 20px 0 10px;
            font-size: 24px;
        }

        .header-nav .notifications .notification-item h4 {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 5px;
        }

        .header-nav .notifications .notification-item p {
            font-size: 13px;
            margin-bottom: 3px;
            color: #919191;
        }

        .header-nav .notifications .notification-item:hover {
            background-color: #f6f9ff;
        }

.header-nav .messages {
    inset: 8px -15px auto auto !important;
}

    .header-nav .messages .message-item {
        padding: 15px 10px;
        transition: 0.3s;
    }

        .header-nav .messages .message-item a {
            display: flex;
        }

        .header-nav .messages .message-item img {
            margin: 0 20px 0 10px;
            max-height: 40px;
        }

        .header-nav .messages .message-item h4 {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 5px;
            color: #444444;
        }

        .header-nav .messages .message-item p {
            font-size: 13px;
            margin-bottom: 3px;
            color: #919191;
        }

        .header-nav .messages .message-item:hover {
            background-color: #f6f9ff;
        }

.header-nav .profile {
    min-width: 240px;
    padding-bottom: 0;
    top: 8px !important;
}

    .header-nav .profile .dropdown-header h6 {
        color: #222d32;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .header-nav .profile .dropdown-header span {
        font-size: 14px;
    }

    .header-nav .profile .dropdown-item {
        font-size: 14px;
        padding: 10px 15px;
        transition: 0.3s;
    }

        .header-nav .profile .dropdown-item i {
            margin-right: 10px;
            font-size: 18px;
            line-height: 0;
        }

        .header-nav .profile .dropdown-item:hover {
            background-color: #f6f9ff;
        }

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar {
    background-color: #343a40;
    bottom: 0;
    left: 0;
    z-index: 999;
    overflow-y: auto;
    position: fixed;
    scrollbar-color: #aab7cf transparent;
    scrollbar-width: thin;
    top: 82px;
    transition: all .3s;
    width: 260px;
}
/*.nav-link:hover {
    list-style: none;
    margin: 0;
    padding: 5px 0 0;
    margin-top: 5px;
    border: 1px solid #ced6e3;
    position: absolute;
    top: 5px;
    left: 128px;
}*/
@media (max-width: 1199px) {
    .sidebar {
    }
}

.sidebar::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #aab7cf;
}

@media (min-width: 1200px) {

    #main,
    #footer {
        margin-left: 250px;
    }
}

@media (max-width: 1199px) {
    .toggle-sidebar .sidebar {
        left: 0;
    }
}

@media (min-width: 1200px) {

    .toggle-sidebar #main,
    .toggle-sidebar #footer {
        margin-left: 0;
    }

    .toggle-sidebar .sidebar {
        left: -300px;
    }
}

.sidebar-nav {
    padding: 0;
    margin: 0;
    list-style: none;
}

    .sidebar-nav li {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .sidebar-nav .nav-item {
        margin-bottom: 1px;
    }

    .sidebar-nav .nav-heading {
        font-size: 11px;
        text-transform: uppercase;
        color: #899bbd;
        font-weight: 600;
        margin: 10px 0 5px 15px;
    }


/* Default state for the switch */
.form-check-input {
    cursor: pointer;
    background-color: #dc3545;
    border-color: #dc3545;
    transition: background-color 0.3s, border-color 0.3s;
}

    .form-check-input:checked {
        background-color: #4bd011 !important;
        border-color: #4bd011 !important;
    }

    .form-check-input:focus {
        border-color: #4a56ce;
        outline: 0;
        box-shadow: 0 0 0 0 rgba(13, 110, 253, 0.25);
    }

.form-check1-input {
    cursor: pointer;
    background-color: #f36573;
    border-color: #dc3545;
    transition: background-color 0.3s, border-color 0.3s;
}

    .form-check1-input:checked {
        background-color: #4bd011 !important;
        border-color: #4bd011 !important;
    }

    .form-check1-input:focus {
        border-color: #4a56ce;
        outline: 0;
        box-shadow: 0 0 0 0 rgba(13, 110, 253, 0.25);
    }

.sidebar-nav .nav-link {
    align-items: center;
    background: transparent;
    color: #fff;
    display: flex;
    white-space: nowrap;
    flex-direction: column;
    font-size: 14px;
    font-weight: 700;
    padding: 8px 24px;
    transition: .3;
}

    .sidebar-nav .nav-link i {
        font-size: 16px;
        margin-right: 10px;
        color: #fff;
    }

    .sidebar-nav .nav-link .collapsed {
        color: #fff;
        background: #2140ac;
    }

        .sidebar-nav .nav-link .collapsed i {
            color: #fff;
        }

    .sidebar-nav .nav-link:hover {
        color: #fff;
        background: rgba(255,255,255,.1);
    }

        .sidebar-nav .nav-link:hover i {
            color: #fff;
        }

    .sidebar-nav .nav-link .bi-chevron-down {
        margin-right: 0;
        transition: transform 0.2s ease-in-out;
    }

    .sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
        transform: rotate(180deg);
    }
    .sidebar-nav .nav-link:not(.collapsed) {
        background: rgba(255,255,255,.1);
    }

    .sidebar-nav .nav-content {
        list-style: none;
        margin: 0;
        padding: 0px 0 0;
    }

    .sidebar-nav .nav-content a {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: #c2c7d0 !important;
        transition: 0.3s;
        padding: 7px 0 7px 27px;
        transition: 0.3s;
    }
.sidebar-nav .nav-link .bi-chevron-down {
    font-size: 10px;
}
.sidebar-open {
    left: 0;
    width: 65px;
    overflow-x:hidden;
}
.sidebar-open:hover{
    width:260px;
    z-index:99999;
}
.sidebar::-webkit-scrollbar{
    visibility:collapse;
}
.sidebar-open:hover::-webkit-scrollbar{
    display:block;
}
    .sidebar-open:hover .nav-link span ,.sidebar-open:hover .nav-content a span{
        visibility: visible;
        transition: .3s all ease;
    }
    .sidebar-open .nav-link span, .sidebar-open .nav-content a span{
        visibility: hidden;
    }
    .sidebar-open .sidebar-nav .nav-link {
        padding: 15px 24px;
    }

    .sidebar-nav .nav-content a i {
        font-size: 6px;
        margin-right: 8px;
        line-height: 0;
        border-radius: 50%;
    }

        .sidebar-nav .nav-content a:hover,
        .sidebar-nav .nav-content a.active {
            color: #fff !important;

        }
        .sidebar-nav .nav-content a:hover {
            color: #fff !important;
        }
            .sidebar-nav .nav-content a.active i {
                background-color: #4154f1;
            }

/*--------------------------------------------------------------
# Dashboard
--------------------------------------------------------------*/
/* Filter dropdown */
.dashboard .filter {
    position: absolute;
    right: 0px;
    top: 15px;
}

    .dashboard .filter .icon {
        color: #aab7cf;
        padding-right: 20px;
        padding-bottom: 5px;
        transition: 0.3s;
        font-size: 16px;
    }

        .dashboard .filter .icon:hover,
        .dashboard .filter .icon:focus {
            color: #4154f1;
        }

    .dashboard .filter .dropdown-header {
        padding: 8px 15px;
    }

        .dashboard .filter .dropdown-header h6 {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1px;
            color: #aab7cf;
            margin-bottom: 0;
            padding: 0;
        }

    .dashboard .filter .dropdown-item {
        padding: 8px 15px;
    }

.select_records_filter {
    width: 100%;
}

/* Info Cards */
.dashboard .info-card {
    padding-bottom: 10px;
}

    .dashboard .info-card h6 {
        font-size: 28px;
        color: #012970;
        font-weight: 700;
        margin: 0;
        padding: 0;
    }

.dashboard .card-icon {
    font-size: 32px;
    line-height: 0;
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    flex-grow: 0;
}

.dashboard .sales-card .card-icon {
    color: #4154f1;
    background: #f6f6fe;
}

.dashboard .revenue-card .card-icon {
    color: #2eca6a;
    background: #e0f8e9;
}

.dashboard .customers-card .card-icon {
    color: #ff771d;
    background: #ffecdf;
}

/* Activity */
.dashboard .activity {
    font-size: 14px;
}

    .dashboard .activity .activity-item .activite-label {
        color: #888;
        position: relative;
        flex-shrink: 0;
        flex-grow: 0;
        min-width: 64px;
    }

        .dashboard .activity .activity-item .activite-label::before {
            content: "";
            position: absolute;
            right: -11px;
            width: 4px;
            top: 0;
            bottom: 0;
            background-color: #eceefe;
        }

    .dashboard .activity .activity-item .activity-badge {
        margin-top: 3px;
        z-index: 1;
        font-size: 11px;
        line-height: 0;
        border-radius: 50%;
        flex-shrink: 0;
        border: 3px solid #fff;
        flex-grow: 0;
    }

    .dashboard .activity .activity-item .activity-content {
        padding-left: 10px;
        padding-bottom: 20px;
    }

    .dashboard .activity .activity-item:first-child .activite-label::before {
        top: 5px;
    }

    .dashboard .activity .activity-item:last-child .activity-content {
        padding-bottom: 0;
    }

/* News & Updates */
.dashboard .news .post-item + .post-item {
    margin-top: 15px;
}

.dashboard .news img {
    width: 80px;
    float: left;
    border-radius: 5px;
}

.dashboard .news h4 {
    font-size: 15px;
    margin-left: 95px;
    font-weight: bold;
    margin-bottom: 5px;
}

    .dashboard .news h4 a {
        color: #012970;
        transition: 0.3s;
    }

        .dashboard .news h4 a:hover {
            color: #4154f1;
        }

.dashboard .news p {
    font-size: 14px;
    color: #777777;
    margin-left: 95px;
}

/* Recent Sales */
.dashboard .recent-sales {
    font-size: 14px;
}

    .dashboard .recent-sales .table thead {
        background: #f6f6fe;
    }

        .dashboard .recent-sales .table thead th {
            border: 0;
        }

    .dashboard .recent-sales .dataTable-top {
        padding: 0 0 10px 0;
    }

    .dashboard .recent-sales .dataTable-bottom {
        padding: 10px 0 0 0;
    }

/* Top Selling */
.dashboard .top-selling {
    font-size: 14px;
}

    .dashboard .top-selling .table thead {
        background: #f6f6fe;
    }

        .dashboard .top-selling .table thead th {
            border: 0;
        }

    .dashboard .top-selling .table tbody td {
        vertical-align: middle;
    }

    .dashboard .top-selling img {
        border-radius: 5px;
        max-width: 60px;
    }

/*--------------------------------------------------------------
# Icons list page
--------------------------------------------------------------*/
.iconslist {
    display: grid;
    max-width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 1.25rem;
    padding-top: 15px;
}

    .iconslist .icon {
        background-color: #fff;
        border-radius: 0.25rem;
        text-align: center;
        color: #012970;
        padding: 15px 0;
    }

    .iconslist i {
        margin: 0.25rem;
        font-size: 2.5rem;
    }

    .iconslist .label {
        font-family: var(--bs-font-monospace);
        display: inline-block;
        width: 100%;
        overflow: hidden;
        padding: 0.25rem;
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #666;
    }

/*--------------------------------------------------------------
# Profie Page
--------------------------------------------------------------*/
.profile .profile-card img {
    max-width: 120px;
}

.profile .profile-card h2 {
    font-size: 24px;
    font-weight: 700;
    color: #2c384e;
    margin: 10px 0 0 0;
}

.profile .profile-card h3 {
    font-size: 18px;
}

.profile .profile-card .social-links a {
    font-size: 20px;
    display: inline-block;
    color: rgba(1, 41, 112, 0.5);
    line-height: 0;
    margin-right: 10px;
    transition: 0.3s;
}

    .profile .profile-card .social-links a:hover {
        color: #012970;
    }

.profile .profile-overview .row {
    margin-bottom: 20px;
    font-size: 15px;
}

.profile .profile-overview .card-title {
    color: #012970;
}

.profile .profile-overview .label {
    font-weight: 600;
    color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit label {
    font-weight: 600;
    color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit img {
    max-width: 120px;
}

/*--------------------------------------------------------------
# F.A.Q Page
--------------------------------------------------------------*/
.faq .basic h6 {
    font-size: 18px;
    font-weight: 600;
    color: #4154f1;
}

.faq .basic p {
    color: #6980aa;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
    padding: 28px 30px;
}

    .contact .info-box i {
        font-size: 38px;
        line-height: 0;
        color: #4154f1;
    }

    .contact .info-box h3 {
        font-size: 20px;
        color: #012970;
        font-weight: 700;
        margin: 20px 0 10px 0;
    }

    .contact .info-box p {
        padding: 0;
        line-height: 24px;
        font-size: 14px;
        margin-bottom: 0;
    }

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
}

    .contact .php-email-form .loading:before {
        content: "";
        display: inline-block;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin: 0 10px -6px 0;
        border: 3px solid #18d26e;
        border-top-color: #eee;
        animation: animate-loading 1s linear infinite;
    }

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 0;
}

    .contact .php-email-form input:focus,
    .contact .php-email-form textarea:focus {
        border-color: #4154f1;
    }

.contact .php-email-form input {
    padding: 10px 15px;
}

.contact .php-email-form textarea {
    padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
    background: #4154f1;
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}

    .contact .php-email-form button[type=submit]:hover {
        background: #5969f3;
    }

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Error 404
--------------------------------------------------------------*/
.error-404 {
    padding: 30px;
}

    .error-404 h1 {
        font-size: 180px;
        font-weight: 700;
        color: #4154f1;
        margin-bottom: 0;
        line-height: 150px;
    }

    .error-404 h2 {
        font-size: 24px;
        font-weight: 700;
        color: #012970;
        margin-bottom: 30px;
    }

    .error-404 .btn {
        background: #51678f;
        color: #fff;
        padding: 8px 30px;
    }

        .error-404 .btn:hover {
            background: #3e4f6f;
        }

@media (min-width: 992px) {
    .error-404 img {
        max-width: 50%;
    }
}

@media (max-width: 575px) {
    .recoard-bata table tbody tr td button {
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 20px;
    }

        .recoard-bata table tbody tr td button i {
            font-size: 10px;
        }

    .recoard-bata table tbody tr td .text-success {
        margin-right: 5px;
    }

    .lms-new-button button:last-child {
        margin-top: 15px;
    }

    /* .select_records_filter {
        width: 50%;
    }*/

    .table td {
        width: 100%;
    }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
    padding: 20px 0;
    font-size: 14px;
    transition: all 0.3s;
    border-top: 1px solid #cddfff;
}

    .footer .copyright {
        text-align: center;
        color: #012970;
    }

    .footer .credits {
        padding-top: 5px;
        text-align: center;
        font-size: 13px;
        color: #012970;
    }

.dualInputFirstWidth {
    width: 36%;
    margin-left: 5%;
    margin-right: 1%;
}

.dualInputSecondWidth {
    width: 53%;
}

.itemAddButton {
    /*height: 27px;
    
    border-radius: 50%;
    width: 27px;
    padding: 0px;*/
    color: #fff !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: green;
    float: right !important;
    margin-right: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.deatials-row {
    justify-content: space-between;
}

.toggle-fullscreen-btn {
    margin-right: 20px;
}

.mylegend {
    float: unset !important;
    width: unset !important;
    padding: unset !important;
    margin-bottom: unset !important;
    font-size: unset !important;
    line-height: unset !important;
}
.myfieldset {
    min-width: unset !important;
    padding: 10px !important;
    margin: 10px 0 !important;
    border: 1px solid #555 !important;
}

.file-uploader {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-width: 100% !important;
    max-width: 508px !important;
    height: 30px !important;
    margin-top: 0px !important;
    border: 1px solid var(--border-color) !important;
    padding: 3px 5px !important;
    border-radius: 5px;
    cursor: pointer;
    -webkit-box-flex: 0;
    flex-grow: 0;
    border: 1px dashed #000000 !important;
}

    .file-uploader > svg {
        width: 20px !important;
        height: 20px !important;
    }

    .file-uploader span {
        margin-left: 5px;
        font-size: 10px !important;
        color: var(--text) !important;
    }


    .file-uploader .file-children {
        display: flex !important;
        justify-content: space-between !important;
        width: 100% !important;
    }

        /*.file-uploader .file-types {
        color: var(--text) !important;
    }*/

        .file-uploader .file-children .sc-fqkvVR span:first-child {
            margin-left: 5px;
            margin-right: 5px;
            font-size: 10px !important;
            color: var(--text) !important;
            text-decoration: underline;
        }

.textEditor .ql-editor {
    height: 100vh !important
}

.HeaderforProductlist {
    background-color: #f0f1f4 !important;
    height: 2rem;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    /*border-top: 0px solid #0dcaf0;*/
}

.Product_container {
    border-top:2px solid #1BB3E6 !important;
    border-right: 1px #ababab solid !important;
    border-left: 1px #ababab solid !important;
    border-bottom: 1px #ababab solid !important;
    border-radius: 5px;
    padding: 0px;
    margin: 0px;
}
/*.Product_container1 {
    border: 1px #9b7542 solid !important;
    border-radius: 5px;
    padding: 0px;
    margin: 20px;
}*/

.Product_container1 {
    border: 1px #ababab solid !important;
    border-radius: 5px;
    overflow: hidden;
}
    .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
    border-radius: 50% !important;
    height: 24px !important;

    min-width: 24px !important;
    color:black !important;
    font-size: 12px !important;
}
/*.nav-tabs .nav-link.active {
    color: white !important;
    background-color: #d8b06d;
    border-color: #d8b06d;
    margin-bottom: calc(-1* var(--bs-nav-tabs-border-width));
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius);
}*/
.container {
    width: 300px;
    padding: 20px;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
}

.did-floating-label-content {
    position: relative;
    margin-bottom: 20px;
}

.did-floating-label {
    color: #1e4c82;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 7px;
    top: 7px;
    padding: 0 5px;
    background: #fff;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.did-floating-input, .did-floating-select {
    font-size: 12px;
    display: block;
    width: 100%;
    height: 32px;
    padding: 0 10px;
    background: #fff;
    color: #323840;
    border: 1px solid #a88551;
    border-radius: 4px;
    box-sizing: border-box;
    &:focus

{
    outline: none;
    ~ .did-floating-label

{
    top: -8px;
    font-size: 13px;
}

}
}

select.did-floating-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

    select.did-floating-select::-ms-expand {
        display: none;
    }

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
    top: -8px;
    font-size: 13px;
}

.did-floating-select:not([value=""]):valid ~ .did-floating-label {
    top: -8px;
    font-size: 13px;
}

.did-floating-select[value=""]:focus ~ .did-floating-label {
    top: 11px;
    font-size: 13px;
}

.did-floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
    background-position: right 15px top 50%;
    background-repeat: no-repeat;
}

.did-error-input {
    .did-floating-input, .did-floating-select

{
    border: 2px solid #9d3b3b;
    color: #9d3b3b;
}

.did-floating-label {
    font-weight: 600;
    color: #9d3b3b;
}

.did-floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
}

}

.input-group {
    display: flex;
    .did-floating-input

{
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 0;
}

}

.input-group-append {
    display: flex;
    align-items: center;
    /*   margin-left:-1px; */
}

.input-group-text {
    display: flex;
    align-items: center;
    font-weight: 400;
    height: 34px;
    color: #323840;
    padding: 0 5px 0 20px;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #3D85D8;
    border-radius: 4px 0 0 4px;
    border-right: none;
}
/*.TableTrhover:hover {
    background-color: rgb(254, 231, 127,0.4) !important;
}*/


.activeTd {
    background: #fee77f;
}

    .activeTd:hover {
        background: #fee77f !important;
    }
.TableTrhover:hover {
    background-color: rgb(254, 231, 127,0.4) !important;
}

.TableTrhover:focus {
    background-color: rgb(254, 231, 127,0.4) !important;
}
/*.modal-header {
    padding:5px!important;
}*/

.fontAWS {
    display: flex !important;
   float:right!important;
}

#home{
    padding:20px;
}


/*.container-fluid {
    --bs-gutter-x: 1.5rem !important;
    --bs-gutter-y: 0;
}

.card {
    height: 50% !important;
}

    .card:nth-child(2),
    .card:nth-child(3) {
        height: 25% !important;
    }

.row {
    display: flex !important;
    flex-wrap: wrap!important;
    margin-top: calc(-1 * var(--bs-gutter-y)) !important;
    margin-right: calc(-0.5 * var(--bs-gutter-x)) !important;
    margin-left: calc(-0.5 * var(--bs-gutter-x))!important;
}
*/


.tox-statusbar__branding {
    display: none !important;
}

.tox-editor-container{
    z-index:0 !important;
}

/*.tox-tinymce{
    height:100vh!important;
}*/

/*.tooltip {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    z-index: 999;
    top: -25px;*/ /* Adjust this value according to your layout */
    /*left: 0;*/ /* Adjust this value according to your layout */
    /*display: none;
}

    .tooltip.active {
        display: block;
    }*/
.custom-tooltip {
    position: relative;
    display: inline-block;
}

    .custom-tooltip::after {
        content: attr(data-tooltip);
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 8px;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        border-radius: 4px;
        font-size: 14px;
        white-space: nowrap;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.3s linear;
    }

    .custom-tooltip:hover::after {
        visibility: visible;
        opacity: 1;
    }

.main-card1 {
    background: #fff;
    margin-bottom: 15px;
    border-radius: 0.5rem;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    box-shadow: 0 2px 14px rgba(38,60,85,.16);
}

    .main-card1 .card1-header:first-child {
        background-color: #fff;
        background-color: #fff;
        padding: 20px 15px;
        padding: 20px;
        padding: 10px 15px;
        border: 0;
        margin: 0 !important;
    }

    .main-card1 .card1-body {
        padding-top: 0;
    }

    .main-card1 .card1-title {
        font-size: 1.10rem;
        font-weight: 500;
        color: #000;
        text-transform: capitalize;
    }

    .main-card1 label {
        color: #414141;
        font-size: 13px;
        margin-bottom: 3px !important;
    }
.dashboard .dash-icon1 {
    height: 55px;
    width: 55px;
    background: #2CBAFB;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    margin-right: 10px;
}

.dashboard .dash-icon2 {
    height: 55px;
    width: 55px;
    background: #CAB9F0;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    margin-right: 10px;
}

.dashboard .dash-icon3 {
    height: 55px;
    width: 55px;
    background: #FDB31B;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    margin-right: 10px;
}

.dashboard .dash-icon4 {
    height: 55px;
    width: 55px;
    background: #C2EED0;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    margin-right: 10px;
}

.dashboard .dash-icon5 {
    height: 55px;
    width: 55px;
    background: #7D57D0;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    margin-right: 10px;
}
.custom-modal {
    display: none;
    position: fixed;
    z-index: 1000;
    background-color: white;
    border: 1px solid #888;
    border-radius: 4px;
    overflow: hidden;
    cursor: move;
}

/*.modal-content {
    padding: 20px;
}*/

.modal-title {
    margin-top: 0;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

    .close:hover,
    .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
.margin_left{
    margin-left:60px !important;
}
.modal-backdrop {
    z-index: 999 !important;
}
.modal-footer {
    justify-content: flex-start;
}

.image-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-container {
    position: relative;
    width: 100px;
    height: 100px;
}

.preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid #ccc;
    padding: 2px;
}

.delete-button {
    position: absolute;
    top: 2px;
    right: 2px;
    background: rgba(255, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.guZdik{
    min-width:80% !important;
}

.customBadge {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative !important;
    box-sizing: border-box;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    min-width: 20px;
    line-height: 1;
    padding: 0 6px;
    height: 20px;
    border-radius: 10px;
    z-index: 1;
    -webkit-transition: -webkit-transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #1976d2;
    color: #fff;
    top: 0;
    right: 0;
    -webkit-transform: scale(1) translate(50%, -50%);
    -moz-transform: scale(1) translate(50%, -50%);
    -ms-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
}

.disabled-auto-complete {
    cursor: not-allowed;
}


.css-1rzb3uu {
    margin-left: 13px !important;
    margin-bottom: 13px !important;
}
/*LIVE CSS BRAND tab*/

.css-1xpj7p6 {
    overflow: hidden !important;
    min-height: 38px !important;
    display: flex !important;
    height: 41px !important;
    background-color: rgb(243, 244, 246) !important;
    border-radius: 10px !important;
    box-shadow: rgb(173, 195, 228) 0px 2px 4px !important;
}
.css-1m6dv83-MuiTabs-root {
    overflow: hidden !important;
    min-height: 38px !important;
    -webkit-overflow-scrolling: touch !important;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    height: 42px !important;
    background-color: #F3F4F6 !important;
    border-radius: 10px !important;
    box-shadow: 0px 2px 4px #adc3e4 !important;
}
/*.heading-boxBrand {
    padding-top: 12px;
    padding-bottom: 0px;
    padding-left: 11px;
    gap:2px !important;
}*/

.modal-header .btn-close {
    position: absolute;
    right: -5px;
    top: -8px;
    width: 12px;
    background-color: #37CBBE;
    height: 12px;
    opacity: .8;
}
.charge-table-wrapper {
    width: 100%;
}
.charge-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 14px;
    text-align: left;
}

    .charge-table th, .charge-table td {
        padding: 12px 15px;
        border: 1px solid #ddd;
    }

    .charge-table th {
        background-color: #f4f4f4;
        font-weight: bold;
        text-transform: uppercase;
    }

    .charge-table td {
        background-color: #fdfdfd;
    }

    .charge-table .HistoryColor {
        color: #2c3e50;
    }

.data-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.radio {
    transform: scale(1.2);
    cursor: pointer;
    background-color:white;
}

.fw-bold {
    font-weight: bold;
}
.scrollable-tbody {
    display: block;
    max-height: 250px;
    overflow-y: auto;
    width: 100%;
}

.css.MuiTab-root {
    font-size: 12px !important;
    width: 181px !important;
    font-weight: 500 !important;
    letter-spacing: 0.4px !important;
    color: rgb(75, 85, 99) !important;
    transition: color 0.3s !important;
}
.css-MuiTabs-root .MuiTab-root {
    font-size: 12px !important;
    width: 181px !important;
    font-weight: 500 !important;
    letter-spacing: 0.4px !important;
    color: rgb(75, 85, 99) !important;
    transition: color 0.3s !important;
}
.textheaderbutton {
    font-size: 10px;
    flex-wrap: nowrap;
    text-wrap: nowrap;
    --bs-btn-padding-x: 0.50rem !important;
}
