
/*.bg-login {
    background: url('../../../public/assets/img/welcome.jpg');
    background-size: 100% 100%;
}*/
.main-login {
    color: white !important;
    background:#fff;
    box-shadow: 2px 1px 10px 1px #d1d1d169;
}

@media screen and (max-width: 575px) {
    .main-login {
        width: 570px;
    }
    .bg-login {
        background-size:cover!important;
        background-position:center;
    }
}

.loading .overlay {
    background-color:rgba(0,0,0,1)!important
}

.img1 {
    max-height: 110px;
    margin: 0px auto;
}

.loader-layout {
    right: 0;
    width: 100%;
    height: 100%; 
    position: fixed;
    top: 0;
    background: rgba(0,0,0,.4);
    left: 0;
    bottom: 0;
    z-index: 999;
    display:flex;
    align-items:center;
    justify-content:center;
}
.loader-img{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    font-size : 25px;
    gap : 15px;
}

.loader-content{
    text-shadow : 0px 0px 5px red;
}

.container{
    width: inherit;
    padding: inherit;
}
.login_main{
    position: relative;
    background-color: #f5f7fa;
    background-image: url(../../../public/assets/img/bg_wrapper.svg);
    min-height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 65%;
    background-position: 63% 190px;
    overflow: hidden;
}
.india_clip{
    position: fixed;
    width: 100%;
    height: 120px;
    background-image: url(../../../public/assets/img/india.svg);
    opacity: .2;
    bottom: 0;
    left: 0;
}
.form-container{
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 3px 6px  hsla(0, 0%, 61%, 1);
    min-height: 580px;
    padding: 30px;
    position: relative;
    z-index: 999;
}
.fxt-column-wrap{
    position: relative;
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 15vh;
    padding-bottom: 15vh;
}
.fxt-middle-content h2{
    max-width: 280px;
    width: 100%;
    font-size: 32px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 20px;
}

.dot_shape {
    right: 0;
    top: -40%;
    transform: rotate(130deg);
}
.login_main .form-control {
    padding: 10px 0px 10px 40px;
    font-size: 15px;
    color: #555;
    border-radius: 30px;
}
.login_main .form-control::placeholder {
    color: #a0a0a0;
}
.login_main .input_icon{
    position: absolute;
    top: 50%;
    transform: translateY(-58%);
    left: 15px;
}
.login_main .input_icon svg{
    fill: rgba(0, 0, 0, 0.26);
}
.login_main .form-control:focus{
    box-shadow: none;
    border-color: #ffae00;
}
.login_main .valid{
    font-size: 14px;
}